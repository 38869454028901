import { Form, notification } from "antd";
import { useState } from "react";

import CurrencyInput from "@components/form/inputs/form-fields/CurrencyInput";
import FormItemWithInfo from "@components/form/inputs/form-fields/FormItemWithInfo";
import {
  currentDepotInvestmentPlanState,
  currentDepotState,
  currentDepotUserUriState
} from "@states/current.depot.state";
import { financialPositionState } from "@states/financial-position.state";
import { useRefreshCurrentDepotInvestmentPlan } from "@states/investment-plan.state";
import { useRefreshDirectDeposits } from "@states/simulate.state";
import useThemedModal from "@theme/hook/useThemedModal";
import { handlerDebounce } from "@utils/helpers";
import { parseNumberToCurrencyString } from "smavesto.core/lib/utils/format/parseNumberToCurrencyString";

import { useRouter } from "next/router";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

import { useApiErrorHandler } from "@hooks/error-handling/useApiErrorHandler";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import GiftCodeInput from "@src/components/other/giftcode/GiftCodeInput";
import { cancellationDateState } from "@src/state/cancellation.state";
import { useRefreshState } from "@src/state/request.state";
import { SP } from "@styled-components/STags";
import validateGiftCode from "smavesto.core/lib/business-logic/gift-codes/validateGiftCode";
import { validatePayInAmount } from "smavesto.core/lib/business-logic/validation/investment-plan/validatePayInAmount";
import isJuniorDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";
import { ModalFormContainer } from "../../Profile/ModalFormContainer";
import { RULES } from "../constants";
import CancellationAlert from "./CancellationAlert";

type ModalFormPaymentProps = {
  destroyModal: () => void;
  source?: "dashboard" | "transactions";
};

export const ModalFormPayment: React.FunctionComponent<
  ModalFormPaymentProps
> = ({ destroyModal, source }) => {
  const { push } = useRouter();

  const [form] = Form.useForm();

  const { contextHolder, openModal, theme } = useThemedModal();

  const [, currentDepot] = useAsyncSelector(currentDepotState);

  const [handleApiErrorWrapper] = useApiErrorHandler();

  const [paymentValue, setPaymentValue] = useState(0);

  const [, financialPositionForDepot] = useAsyncSelector(
    financialPositionState("currentDepotOwner")
  );

  const [, userUri] = useAsyncSelector(currentDepotUserUriState);

  const [, transactions] = useAsyncSelector(currentDepotInvestmentPlanState);

  const refreshInvestmentPlan =
    useRefreshCurrentDepotInvestmentPlan("currentDepotOwner");

  const refreshGiftCode = useRefreshState("giftcode", "currentDepotOwner");

  const refreshDirectDeposits = useRefreshDirectDeposits("currentDepotOwner");

  const [, cancellationDate] = useAsyncSelector(
    cancellationDateState("currentDepotOwner")
  );

  const refreshDepots = useRefreshState("depots", "loggedInUser");

  const formIsValid = (): boolean => {
    if (paymentValue < 50) return false;

    return true;
  };

  const hasValidationError = (value: number) => {
    const validationError = validatePayInAmount(
      value || 0,
      financialPositionForDepot?.liquidCapital || 0,
      currentDepot && isJuniorDepot(currentDepot)
    );

    if (validationError) {
      openModal("confirm", {
        icon: null,
        width: 500,
        centered: true,
        content: validationError,
        okText: "Ja",
        cancelText: "Nein",
        onOk: () => push("/dashboard/profile?section=financial-position")
      });
      setPaymentValue(0);
      return true;
    }

    if ((paymentValue || 0) > 10000) {
      openModal("info", {
        onOk: performFinish,
        content:
          source === "dashboard"
            ? `Beträge über 10.000,00 € können Sie zu Ihrer Sicherheit ausschließlich per Überweisung einzahlen. Die entsprechenden Kontodaten - IBAN, BIC und Verwendungszweck - finden Sie auch in Ihrem elektronischen Smavesto-Postfach oder im Bereich Transaktionen in der Transaktionshistorie. Bitte geben Sie bei Ihrer Überweisung unbedingt den genannten Verwendungszweck an.`
            : `Beträge über 10.000,00 € können Sie zu Ihrer Sicherheit ausschließlich per Überweisung einzahlen. Die entsprechenden Kontodaten - IBAN, BIC und Verwendungszweck - finden Sie auch in Ihrem elektronischen Smavesto-Postfach oder weiter unten auf dieser Seite in der Transaktionshistorie. Bitte geben Sie bei Ihrer Überweisung unbedingt den genannten Verwendungszweck an.`
      });
      return true;
    }

    return false;
  };

  const onPaymentChange = (value: any) => {
    setPaymentValue(value || 0);
  };

  const onFinish = async (values: any) => {
    if (hasValidationError(paymentValue)) {
      return;
    }

    performFinish(values);
  };

  const performFinish = async (values: any) => {
    const enteredGiftCode = values.giftcode;

    if (enteredGiftCode) {
      /* The user has entered a giftcode */
      const giftCodeValidation = await validateGiftCode(
        smavestoCore,
        enteredGiftCode,
        "payment",
        paymentValue,
        theme
      );

      if (isApiErrorResponse(giftCodeValidation)) {
        form.setFields([
          {
            name: "giftcode",
            errors: [
              giftCodeValidation.humanReadableError ||
                "Der Gutscheincode ist nicht korrekt."
            ]
          }
        ]);
        return;
      }

      /* reset giftcode errors */
      form.setFields([
        {
          name: "giftcode",
          errors: []
        }
      ]);
    }

    if (paymentValue >= 50 && currentDepot && userUri) {
      const onPayConfirmed = async () => {
        const depositMoneyResult =
          await smavestoCore.services.investmentPlan.depositMoney(
            userUri,
            currentDepot.id,
            paymentValue
          );

        if (!isApiErrorResponse(depositMoneyResult)) {
          await smavestoCore.services.keyValueStore.confirmGiftCodeIfThereIsOneForThisUser(
            userUri,
            "payment"
          );

          if (paymentValue > 10000) {
            notification.success({
              message: `Bitte überweisen Sie den  Betrag den Sie einzahlen möchten.`,
              description:
                "Die entsprechenden Kontodaten - IBAN, BIC und Verwendungszweck - finden Sie auch in Ihrem elektronischen Smavesto-Postfach oder im Bereich Transaktionen in der Transaktionshistorie.",
              placement: "top",
              duration: 10
            });
          } else {
            notification.success({
              message: `Ihre Einzahlung war erfolgreich.`,
              description:
                "Bitte haben Sie nach der Eingabe ein paar Tage Geduld, bis der Betrag in Ihrem Dashboard sichtbar ist.",
              placement: "top",
              duration: 10
            });
          }
          refreshInvestmentPlan();
          refreshDirectDeposits();
          refreshDepots();
          refreshGiftCode();
        } else {
          handleApiErrorWrapper(depositMoneyResult, "modal", "error");
        }

        destroyModal();
      };

      openModal("confirm", {
        okText: "Ja",
        cancelText: "Nein",
        onOk: onPayConfirmed,
        title: "Bitte bestätigen",
        width: 500,
        centered: true,
        icon: null,
        content: (
          <div>
            <p>
              Sind Sie sicher, dass Sie den Betrag von{" "}
              {parseNumberToCurrencyString(paymentValue)} wirklich einzahlen
              wollen?
            </p>
          </div>
        )
      });
    }
  };

  if (cancellationDate) {
    return (
      <CancellationAlert
        juniorDepot={currentDepot && isJuniorDepot(currentDepot)}
        destroyModal={destroyModal}
      />
    );
  }

  return (
    <ModalFormContainer
      title="Einzahlungen"
      submitBtnLabel="Betrag einzahlen"
      isSubmit={false}
      form={form}
      submitDisabled={!formIsValid()}
      onFinish={onFinish}
      onCancel={destroyModal}
    >
      <SP fontWeight={700}>
        Hier können Sie einmalige Einzahlungen vornehmen. Bitte haben Sie nach
        Eingabe ein paar Tage Geduld, bis der Betrag in Ihrem Dashboard sichtbar
        ist.
      </SP>

      <FormItemWithInfo name="modalPayment" rules={RULES.modalPayment}>
        <CurrencyInput
          placeholder="min. 50€"
          onChange={handlerDebounce(onPaymentChange, 500)}
          bordered={false}
        />
      </FormItemWithInfo>

      <SP
        color={theme.palette.primary}
        fontWeight={700}
        fontSize="16px"
        margin="8px 0 0 0"
      >
        {transactions && transactions.openDepositSum > 0
          ? `Bitte beachten Sie: Aktuell sind Einzahlungen i.H.v. ${parseNumberToCurrencyString(
              transactions.openDepositSum
            )} bereits in Verarbeitung.`
          : ""}
      </SP>

      <GiftCodeInput source="payment" parentForm={form} />

      {contextHolder}
    </ModalFormContainer>
  );
};

import type { RadioGroupProps } from "antd";
import { Radio } from "antd";
import styled from "styled-components";

import { ThemeContext } from "@pages/_app";
import React, { useContext } from "react";
import type { AppTheme } from "smavesto.core/lib/static/themes/AppTheme";
import type { AppThemeSetting } from "smavesto.core/lib/static/themes/AppThemeSetting";

export const SRadioGroupComponent = (
  theme: AppTheme,
  appModeTheme?: AppThemeSetting,
  background?: string
) => styled(Radio.Group)`
  background: ${background};
  padding: 5px;
  border: 2px solid ${theme.palette.primary};
  border-radius: 7px;
  color: inherit;

  .ant-radio-button-wrapper {
    background: transparent;
    color: ${appModeTheme?.secondLayerTextColor};
    border: none;
    box-shadow: none;
    border-radius: 4px;
  }

  .ant-radio-button-wrapper-checked span {
    color: ${theme.palette.primaryTextColor};
  }

  .ant-radio-button-wrapper::before,
  .ant-radio-button-wrapper-checked::before,
  .ant-radio-button-wrapper-checked:active::before {
    background: transparent;
    width: 0;
    height: 0;
  }

  .ant-radio-button-wrapper-checked {
    border-radius: 4px;
  }

  .ant-radio-button-wrapper-checked:focus-within {
    box-shadow: none;
  }

  &
    .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):focus-within {
    box-shadow: none;
  }
`;

export const SRadioGroup: React.FunctionComponent<RadioGroupProps> = props => {
  const { theme, appModeTheme } = useContext(ThemeContext);

  const RenderComponent = SRadioGroupComponent(
    theme,
    appModeTheme,
    appModeTheme?.colorSchema === "light"
      ? appModeTheme.secondLayerBackgroundColor
      : "transparent"
  );

  return <RenderComponent {...props} />;
};

import { type RadioChangeEvent } from "antd";

import CurrencyInput from "@components/form/inputs/form-fields/CurrencyInput";
import FormItemWithInfo from "@components/form/inputs/form-fields/FormItemWithInfo";
import { RadioGroup } from "@components/form/inputs/form-fields/RadioGroup";
import { LoadingHintText } from "@components/layout/loading-indicator/loading-hint-text";
import { handlerDebounce } from "@utils/helpers";

import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { cancellationDateState } from "@src/state/cancellation.state";
import { currentDepotState } from "@src/state/current.depot.state";
import isJuniorDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";
import { ModalFormContainer } from "../../Profile/ModalFormContainer";
import CancellationAlert from "./CancellationAlert";
import { useSavingsPlan } from "./useSavingsPlan";
import { minMonthlyAmountValidator } from "./validators";

interface ModalFormSavingsPlanProps {
  destroyModal: () => void;
}

export const RADIO_CHARGE_DATE = [
  { label: "Sparbetrag zum 1. eines Monats abbuchen", value: 1 },
  { label: "Sparbetrag zum 15. eines Monats abbuchen", value: 15 }
];

export const ModalFormSavingsPlan: React.FunctionComponent<
  ModalFormSavingsPlanProps
> = ({ destroyModal }) => {
  const [, cancellationDate] = useAsyncSelector(
    cancellationDateState("currentDepotOwner")
  );

  const [, currentDepot] = useAsyncSelector(currentDepotState);

  const {
    form,
    depotSavingAmount,
    contextHolder,
    monthlyAmount,
    onMonthlyAmountChange,
    onFinish,
    setPaymentChargeDate
  } = useSavingsPlan(destroyModal);

  if (cancellationDate) {
    return (
      <CancellationAlert
        juniorDepot={currentDepot && isJuniorDepot(currentDepot)}
        destroyModal={destroyModal}
      />
    );
  }

  return (
    <ModalFormContainer
      title="Sparbetrag ändern"
      submitBtnLabel="Sparbetrag ändern"
      isSubmit={false}
      form={form}
      onFinish={onFinish}
      onCancel={destroyModal}
    >
      {!depotSavingAmount ? (
        <LoadingHintText loadingText="Bitte warten ..." />
      ) : (
        <RadioGroup
          name="paymentChargeDate"
          label="Hier können Sie Ihren derzeitigen Sparplan ändern."
          items={RADIO_CHARGE_DATE}
          onChange={(e: RadioChangeEvent) =>
            setPaymentChargeDate(e.target.value)
          }
        />
      )}

      <FormItemWithInfo
        name="monthlyAmount"
        rules={[{ validator: minMonthlyAmountValidator }]}
      >
        <CurrencyInput
          placeholder="z.B. 50€"
          value={monthlyAmount}
          onChange={handlerDebounce(onMonthlyAmountChange, 500)}
          bordered={false}
        />
      </FormItemWithInfo>
      {contextHolder}
    </ModalFormContainer>
  );
};

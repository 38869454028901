import type { FormInstance } from "antd/es/form";
import type { ReactNode } from "react";

import { FormInModal } from "@components/form/inputs/form-fields/FormInModal";
import {
  SActionButtonsWrapper,
  SButton
} from "@styled-components/form/SButtons";

import { FormHeader } from "./FormHeader";

interface Props<T> {
  title: string;
  submitBtnLabel: string;
  isSubmit?: boolean;
  form?: FormInstance<T>;
  onFinish: (values: T) => void;
  onCancel?: () => void;
  submitBtnIcon?: ReactNode;
  children: ReactNode;
  submitDisabled?: boolean;
}

export function ModalFormContainer<T extends Record<string, unknown> = any>({
  title,
  submitBtnLabel,
  isSubmit,
  form,
  onFinish,
  onCancel,
  submitBtnIcon,
  submitDisabled,
  children
}: Props<T>) {
  return (
    <FormInModal<T>
      form={form}
      onFinish={onFinish}
      style={{ padding: "36px 50px" }}
      withoutHeader
    >
      <FormHeader title={title} />

      {children}

      {onCancel ? (
        <SActionButtonsWrapper>
          <SButton className="reset" onClick={onCancel}>
            Abbrechen
          </SButton>

          <SButton
            htmlType="submit"
            type="primary"
            icon={submitBtnIcon}
            disabled={submitDisabled}
            loading={isSubmit}
          >
            {submitBtnLabel}
          </SButton>
        </SActionButtonsWrapper>
      ) : (
        <SActionButtonsWrapper style={{ margin: 0 }}>
          <SButton
            htmlType="submit"
            type="primary"
            icon={submitBtnIcon}
            disabled={submitDisabled}
            loading={isSubmit}
          >
            {submitBtnLabel}
          </SButton>
        </SActionButtonsWrapper>
      )}
    </FormInModal>
  );
}

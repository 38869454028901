import { currentDepotOnboardingState } from "@states/current.depot.state";
import { useAsyncSelector } from "@hooks/useAsyncSelector";

import type { CustomTooltipProps } from "./CustomTooltip";
import CustomTooltip from "./CustomTooltip";

const TestModeTooltip: React.FunctionComponent<CustomTooltipProps> = ({
  placement,
  disabled,
  title,
  style,
  children
}) => {
  const [, onboarding] = useAsyncSelector(currentDepotOnboardingState);

  const isTestMode = !onboarding?.liveDepot;

  const testModeTitle = isTestMode
    ? "Sie haben ein Testdepot und können diese Aktion nicht nutzen"
    : undefined;

  const disabledForChildren = disabled || isTestMode;

  if (isTestMode)
    return (
      <CustomTooltip
        title={title || testModeTitle}
        placement={placement}
        style={style}
        disabled={disabledForChildren}
      >
        {children}
      </CustomTooltip>
    );

  return children;
};

export default TestModeTooltip;

export const RULES = {
  modalPayment: [
    {
      pattern: /^([5-9]\d|[1-9]\d{2,})$/,
      message: "Der minimale Einzahlungsbetrag liegt bei 50€"
    }
  ],
  modalPayout: [
    {
      required: true,
      pattern: /[1-9][0-9]*$/,
      message: "Der minimale Auszahlungsbetrag liegt bei 1€"
    }
  ]
};

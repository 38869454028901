import ReallocationTooltip from "../tooltips/ReallocationTooltip";
import BasicHint from "./BasicHint";

const NextReallocationDepotHint: React.FunctionComponent = () => {
  return (
    <BasicHint noBackground mt={2} mr={1}>
      Einzahlungen und Sparbeträge werden mit der nächsten Reallokation
      <ReallocationTooltip /> angelegt, diese beginnt i.d.R. jeweils an dem
      Freitag vor dem letzten Wochenende des Monats.
    </BasicHint>
  );
};

export default NextReallocationDepotHint;

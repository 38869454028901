import { Form, notification } from "antd";
import { useState } from "react";

import CurrencyInput from "@components/form/inputs/form-fields/CurrencyInput";
import FormItemWithInfo from "@components/form/inputs/form-fields/FormItemWithInfo";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import {
  currentDepotInvestmentPlanState,
  currentDepotState,
  currentDepotUserUriState
} from "@states/current.depot.state";
import { useRefreshCurrentDepotInvestmentPlan } from "@states/investment-plan.state";
import { SP } from "@styled-components/STags";
import useThemedModal from "@theme/hook/useThemedModal";
import { handlerDebounce } from "@utils/helpers";
import { parseNumberToCurrencyString } from "smavesto.core/lib/utils/format/parseNumberToCurrencyString";

import { useApiErrorHandler } from "@src/hooks/error-handling/useApiErrorHandler";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import useOnboardingLabels from "@src/hooks/onboarding/labels/useOnboardingLabels";
import { cancellationDateState } from "@src/state/cancellation.state";
import isJuniorDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";
import { ModalFormContainer } from "../../Profile/ModalFormContainer";
import { RULES } from "../constants";
import CancellationAlert from "./CancellationAlert";
import { availableBalanceValidator } from "./validators";

type ModalFormPayoutProps = { destroyModal: () => void };

export const ModalFormPayout: React.FunctionComponent<ModalFormPayoutProps> = ({
  destroyModal
}) => {
  const { contextHolder, openModal, theme } = useThemedModal();

  const labels = useOnboardingLabels("dashboard");

  const [handleApiError] = useApiErrorHandler();

  const [form] = Form.useForm();

  const [payoutValue, setPayoutValue] = useState(0);

  const [, userUri] = useAsyncSelector(currentDepotUserUriState);

  const [, investmentPlan] = useAsyncSelector(currentDepotInvestmentPlanState);

  const [, currentDepot] = useAsyncSelector(currentDepotState);

  const [, cancellationDate] = useAsyncSelector(
    cancellationDateState("currentDepotOwner")
  );

  const refreshInvestmentPlan =
    useRefreshCurrentDepotInvestmentPlan("currentDepotOwner");

  const availableBalance = Math.floor(
    (currentDepot?.balance ?? 0) - (investmentPlan?.openPayOutSum ?? 0)
  );

  const onPaymentChange = (value: any) => setPayoutValue(value || 0);

  const onFinish = async () => {
    if (currentDepot && userUri) {
      const onPayConfirmed = async () => {
        const payOutResult =
          await smavestoCore.services.investmentPlan.payoutMoney(
            userUri,
            currentDepot.id,
            payoutValue
          );

        if (isApiErrorResponse(payOutResult)) {
          handleApiError(payOutResult, "modal", "error");
        } else {
          notification.success({
            message: `Ihre Auszahlung war erfolgreich.`,
            description:
              "Bitte haben Sie nach der Eingabe ein paar Tage Geduld, bis der Betrag auf Ihr hinterlegtes Konto gutgeschrieben wird.",
            placement: "top",
            duration: 10
          });
          refreshInvestmentPlan();
          destroyModal();
        }
      };

      const payOutSumEqualsAllAssetValue =
        payoutValue >= parseFloat(availableBalance.toFixed(0));

      openModal("confirm", {
        okText: "Ja",
        cancelText: "Nein",
        onOk: () => onPayConfirmed(),
        title: "Bitte bestätigen",
        width: 500,
        centered: true,
        icon: null,
        content: payOutSumEqualsAllAssetValue ? (
          <div>
            <p>
              {labels.dashboard.payout.equalsAllAssetValue
                .split("\n")
                .map(a => (
                  <>
                    {a}
                    <br />
                  </>
                ))}
            </p>
            <p>
              Sind Sie sicher, dass Sie den Betrag von{" "}
              {parseNumberToCurrencyString(payoutValue)} wirklich auszahlen
              wollen?
            </p>
          </div>
        ) : (
          <div>
            <p>
              Sind Sie sicher, dass Sie den Betrag von{" "}
              {parseNumberToCurrencyString(payoutValue)} wirklich auszahlen
              wollen?
            </p>
          </div>
        )
      });
    }
  };

  if (cancellationDate) {
    return (
      <CancellationAlert
        juniorDepot={currentDepot && isJuniorDepot(currentDepot)}
        destroyModal={destroyModal}
      />
    );
  }

  return (
    <ModalFormContainer
      title="Auszahlungen"
      submitBtnLabel="Betrag auszahlen"
      submitDisabled={availableBalance < payoutValue}
      form={form}
      onFinish={onFinish}
      onCancel={destroyModal}
    >
      <SP fontWeight={700}>
        Ihr max. Auszahlungsbetrag (unter Berücksichtigung noch offener
        Transaktionen) beträgt aktuell:{" "}
        {parseNumberToCurrencyString(availableBalance)}.
      </SP>
      <SP fontWeight={700}>
        Kursschwankungen und steuerliche Abzüge können zu Abweichungen Ihres
        gewünschten Auszahlungsbetrages führen.
        <br />
      </SP>
      <FormItemWithInfo
        name="modalPayout"
        rules={[
          ...RULES.modalPayout,
          { validator: availableBalanceValidator(availableBalance) }
        ]}
      >
        <CurrencyInput
          placeholder="z.B. 500€"
          onChange={handlerDebounce(onPaymentChange, 500)}
          bordered={false}
          disabled={currentDepot?.balance === 0}
        />
      </FormItemWithInfo>
      <SP
        color={theme.palette.primary}
        fontWeight={700}
        fontSize="16px"
        margin="8px 0 0 0"
      >
        {investmentPlan && investmentPlan.openPayOutSum > 0
          ? `Bitte beachten Sie: Aktuell sind Auszahlungen i.H.v. ${parseNumberToCurrencyString(
              investmentPlan.openPayOutSum
            )} bereits in Verarbeitung.`
          : ""}
      </SP>
      {contextHolder}
    </ModalFormContainer>
  );
};

import { Form, notification } from "antd";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import {
  currentDepotFinancialPositionState,
  currentDepotSavingAmountState,
  currentDepotState
} from "@states/current.depot.state";
import { useRefreshDepot } from "@states/depot.state";
import { useRefreshCurrentDepotInvestmentPlan } from "@states/investment-plan.state";
import { useRefreshInvestmentSettings } from "@states/investment-settings.state";
import useThemedModal from "@theme/hook/useThemedModal";
import { parseNumberToCurrencyString } from "smavesto.core/lib/utils/format/parseNumberToCurrencyString";

import { useApiErrorHandler } from "@src/hooks/error-handling/useApiErrorHandler";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import isUnderageDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";
import type SavingAmountDto from "smavesto.core/lib/types/dto/depot/SavingAmountDto";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

export const useSavingsPlan = (destroyModal: () => void) => {
  const { push } = useRouter();
  const [form] = Form.useForm();

  const [handleApiError] = useApiErrorHandler();

  const { contextHolder, openModal } = useThemedModal();

  const [monthlyAmount, setMonthlyAmount] = useState(0);

  const [paymentChargeDate, setPaymentChargeDate] = useState(1);

  const [, currentDepot] = useAsyncSelector(currentDepotState);

  const [, financialPosition] = useAsyncSelector(
    currentDepotFinancialPositionState
  );

  const isJuniorDepot = currentDepot ? isUnderageDepot(currentDepot) : false;

  const isDebitOrder = monthlyAmount <= 5000;

  const isDeactivation = monthlyAmount === 0;

  const setInitValues = useCallback(
    (res: SavingAmountDto | undefined) => {
      if (!res) return;
      setMonthlyAmount(res.monthlyAmount);
      setPaymentChargeDate(res.paymentChargeDate);

      form.setFieldsValue({
        paymentChargeDate: res.paymentChargeDate ?? 1,
        monthlyAmount: res.monthlyAmount
      });
    },
    [form]
  );

  const [, depotSavingAmount] = useAsyncSelector(
    currentDepotSavingAmountState,
    setInitValues
  );

  const refreshDepotSavingAmount = useRefreshDepot("currentDepotOwner");

  const refreshInvestmentSettings =
    useRefreshInvestmentSettings("currentDepotOwner");

  const refreshInvestmentPlan =
    useRefreshCurrentDepotInvestmentPlan("currentDepotOwner");

  const isMonthlyAmountValid = (value: number): boolean => {
    if ((value || 0) > (financialPosition?.monthlyIncome ?? 0)) {
      openModal("confirm", {
        icon: null,
        width: 500,
        centered: true,
        content: `Der monatliche Betrag darf das monatliche Einkommen nicht übersteigen. Möchten Sie Ihr monatliches Einkommen ändern?`,
        okText: "Ja",
        cancelText: "Nein",
        onOk: () => push("/dashboard/profile?section=financial-position"),
        onCancel: () => {
          setMonthlyAmount(financialPosition?.monthlyIncome ?? 0);
          form.setFieldsValue({
            monthlyAmount: financialPosition?.monthlyIncome ?? 0
          });
        }
      });

      return false;
    }

    return true;
  };

  const onMonthlyAmountChange = (value: number | null) => {
    setMonthlyAmount(value || 0);
  };

  const updateDepotSavingAmount = async (
    monthlyAmount: number,
    paymentChargeDate: number
  ) => {
    if (!currentDepot) return;

    const result = await smavestoCore.services.depot.putMonthlyAmount(
      currentDepot.id,
      {
        monthlyAmount,
        paymentChargeDate
      }
    );

    if (isApiErrorResponse(result)) {
      handleApiError(result, "modal", "error");
      return;
    }

    refreshDepotSavingAmount();
    refreshInvestmentSettings();
    refreshInvestmentPlan();
  };

  const onFinish = async () => {
    if (!isMonthlyAmountValid(monthlyAmount)) return;

    const onConfirmed = async () => {
      const result = await updateDepotSavingAmount(
        monthlyAmount,
        paymentChargeDate
      );

      if (isApiErrorResponse(result)) {
        handleApiError(result, "modal", "error");
        return;
      }

      notification.success({
        message: `Der Sparbetrag wurde erfolgreich angepasst!`,
        placement: "top"
      });
      destroyModal();
    };

    function Content() {
      if (isDeactivation) {
        return <p>Möchten Sie Ihren Sparplan aussetzen?</p>;
      }
      if (isDebitOrder) {
        return (
          <p>
            Möchten Sie Ihren Sparbetrag auf{" "}
            {parseNumberToCurrencyString(monthlyAmount)} ändern und diesen
            künftig monatlich zum {paymentChargeDate}. von Ihrem hinterlegtem
            Konto einziehen lassen?
          </p>
        );
      }
      // This is not a Debit Order
      if (isJuniorDepot) {
        return (
          <>
            <p>
              Sparplanbeträge über 5.000,00 € können Sie zu Ihrer Sicherheit
              ausschließlich per Überweisung einzahlen.
            </p>

            <p>
              Möchten Sie den Sparbetrag Ihres Kindes auf{" "}
              {parseNumberToCurrencyString(monthlyAmount)} ändern und diesen
              künftig monatlich zum {paymentChargeDate}. überweisen?
            </p>
            <p>Die Details zur Überweisung erhalten Sie per E-Mail.</p>
          </>
        );
      }
      return (
        <>
          <p>
            Sparplanbeträge über 5.000,00 € können Sie zu Ihrer Sicherheit
            ausschließlich per Überweisung einzahlen.
          </p>

          <p>
            Möchten Sie Ihren Sparbetrag auf{" "}
            {parseNumberToCurrencyString(monthlyAmount)} ändern und diesen
            künftig monatlich zum {paymentChargeDate}. von Ihrem Konto
            überweisen?
          </p>
          <p>Die Details zur Überweisung erhalten Sie per E-Mail.</p>
        </>
      );
    }

    openModal("confirm", {
      okText: "Ja",
      cancelText: "Nein",
      onOk: () => onConfirmed(),
      title: "Bitte bestätigen",
      width: 500,
      centered: true,
      icon: null,
      content: <Content />
    });
  };

  return {
    form,
    depotSavingAmount,
    contextHolder,
    monthlyAmount,
    onMonthlyAmountChange,
    onFinish,
    setPaymentChargeDate
  };
};

import { ModalFormContainer } from "../../Profile/ModalFormContainer";

interface CancellationAlertProps {
  destroyModal: () => void;
  juniorDepot?: boolean;
}

const CancellationAlert: React.FunctionComponent<CancellationAlertProps> = ({
  destroyModal,
  juniorDepot
}) => {
  return (
    <ModalFormContainer
      title="Hinweis"
      submitBtnLabel="OK"
      isSubmit={false}
      onFinish={destroyModal}
    >
      {juniorDepot ? (
        <>
          Die Eingabe von Ein- und Auszahlungen sowie die Anpassung des
          Sparplans Ihres Kindes ist nicht mehr möglich, da die
          Vermögensverwaltung bereits bei uns gekündigt wurde. Das Vermögen
          Ihres Kindes wird kurzfristig ausgezahlt. Es besteht kein weiterer
          Handlungsbedarf Ihrerseits.
        </>
      ) : (
        <>
          Die Eingabe von Ein- und Auszahlungen sowie die Anpassung Ihres
          Sparplans ist nicht mehr möglich, da Sie Ihre Vermögensverwaltung
          bereits bei uns gekündigt haben. Ihr Vermögen wird Ihnen kurzfristig
          ausgezahlt. Es besteht kein weiterer Handlungsbedarf Ihrerseits.
        </>
      )}
    </ModalFormContainer>
  );
};

export default CancellationAlert;

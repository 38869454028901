import type { FC } from "react";
import React from "react";

import type { PropsWithChildren } from "@src/interfaces/jsx.interface";

type Props = { title: string } & PropsWithChildren;
export const FormHeader: FC<Props> = ({ title, children }) => (
  <header
    style={{
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 10
    }}
  >
    <h2 style={{ color: "inherit", textAlign: "center" }}>{title}</h2>
    {children}
  </header>
);

import type { RuleObject } from "antd/lib/form";

export const minMonthlyAmountValidator = (_: RuleObject, value: number) =>
  value < 50 && value !== 0
    ? Promise.reject(
        new Error(
          "Die kleinste mtl. Einzahlung liegt bei 50€ im Monat. Wenn Sie keine mtl. Einzahlung wünschen, geben Sie 0€ ein."
        )
      )
    : Promise.resolve();

export const availableBalanceValidator =
  (availableBalance: number) => (_: RuleObject, value: number) =>
    value > availableBalance
      ? Promise.reject(
          new Error(
            "Der eingegebene Betrag darf Ihr verfügbares Guthaben nicht überschreiten!"
          )
        )
      : Promise.resolve();
